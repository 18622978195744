/**
 * Finds fields within a section's groups by their names.
 * 
 * @param {Object} section - The section containing groups of fields.
 * @param {Array<string>} fieldNames - The list of field names to search for.
 * @returns {Array<Object>} An array of found fields matching the provided names.
 */
export const formFieldsByNames = (section, fieldNames) => {
    const groupSet = new Set(section.groups);
    const fieldNameSet = new Set(fieldNames);

    const foundFields = [];

    fieldNameSet.forEach(fieldName => {
        groupSet.forEach(group => {
            const foundField = findFieldInGroup(fieldName, group);

            if (foundField) {
                foundFields.push(foundField);
            }
        });
    });

    return foundFields.filter(Boolean);
}

/**
 * Finds a specific field by name within a group.
 * 
 * @param {string} fieldName - The name of the field to find.
 * @param {Object} group - The group containing fields to search within.
 * @returns {Object|null} The field object if found, or null if not found.
 */
function findFieldInGroup(fieldName, group) {
    return group.fields.find(field => field.name === fieldName) || null;
}
