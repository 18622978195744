import { AWAITING_SIGNATURE_KEY, AWAITING_SIGNATURE_LABEL, CHECKLIST_NOT_DOWNLOADED_KEY, CHECKLIST_NOT_DOWNLOADED_LABEL } from "./strings";
import { taxReturnStatuses } from "./taxReturn";

export const STATISTICS_TILES = [
  { label: 'Total Clients Provisioned', key: 'total', query: null },
  { label: 'Clients Logged In', key: 'loggedIn', query: ['firstLogIn ine'] },
  { label: 'Clients Not Started', key: 'notStarted', query: ['customStatus co not started'] },
  { label: 'Clients Marked Complete', key: 'submitted', query: ['customStatus co completed'] },
  { label: 'Clients Locked', key: 'locked', query: ['customStatus co locked',] },
  { label: 'Locked Clients With New Files', key: 'newDocumentCount', query: ['newDocumentCount gt 0'] },
  {
    label: 'Extended Clients',
    key: 'extension',
    query: ['extension eq true'],
    customFilter: (item) => !item.customStatus.includes('completed')
  },
  { label: 'eOrganize', key: 'eOrganize', query: ['eOrganize eq true'] },
  {
    label: AWAITING_SIGNATURE_LABEL, key: AWAITING_SIGNATURE_KEY,
    query: [`taxReturnStatus in ["${taxReturnStatuses.sent}", "${taxReturnStatuses.partial}", "${taxReturnStatuses.declined}"]`]
  },

  { label: CHECKLIST_NOT_DOWNLOADED_LABEL, key: CHECKLIST_NOT_DOWNLOADED_KEY, query: ['checklist_downloaded eq false'] },

  { label: 'Clients Provisioned to Spoke', key: 'spoke', query: ['assignedToSpoke eq true'] },
  // {
  //   label: 'Suppressed Notifications',
  //   key: 'suppressedAllScheduleNotifications',
  //   query: null,
  //   customFilter: (item) =>
  //     item.taxpayer?.suppressedAllScheduleNotifications === true ||
  //     item.spouse?.suppressedAllScheduleNotifications === true,
  // },
];

export const ASSIGNED_TO_ME = 'Assigned to Me';
export const SHOW_ALL = 'All';