import _ from 'lodash';
import { isNotNil } from './index';

// isSubsection, isSubSectionExpanded, isVisible
const omittedCardKeys = [ 'sections', 'isExpanded', 'entitiesList' ];

// isVisible, groupType
const omittedGroupKeys = [ 'groupType', 'prePopulateControls', 'prePopulateIndexes', 'lineSections', 'axcessGroup', 'entityIdentifiers', 'lineSectionStyles' ];

// Sections
const omittedEntityKeys = [ 'entitiesList' ];

// triggered
const omittedFieldKeys  = [ 'col', 'class', 'maxDate', 'align', 'placeholder', 'prior', 'validInput', 'isSingleColumn', 'pullCalculation', 'labelFormat', 'maxLength', 'styles' ];

const parseFooterData = (footer) => {
    const { name, label, amount } = footer;

    return {
        name,
        label,
        amount
    };
};

const parseFieldData = (field) => {
    if (!field) {
        return {};
    }

    const { default: defaultValue, value, ...fieldProps } = field;

    const filteredFieldProps = _.omit(fieldProps, omittedFieldKeys);
    
    // TODO: when 'default' is changed to 'value' property, can remove grabbing 'default' property
    const payload = {
        ...(isNotNil(value || defaultValue) && { value: value || defaultValue }),
        ...filteredFieldProps,
    };

    // Delete isRendered before save, this prop need for runtime use only
    delete payload.isRendered;

    return payload;
};

const parseGroupData = (group) => {
    if (!group) {
        return {};
    }

    const { fields, lineItems, lineItemDetails, entities, ...groupProps } = group;

    const filteredGroupProps = _.omit(groupProps, omittedGroupKeys);

    const returnGroup = { ...filteredGroupProps };

    if (lineItems) {
        const parsedLineItems = lineItems.map((row) => row.map(parseFieldData));

        if (lineItemDetails && lineItemDetails.footerLabels) {
            const parsedLineItemDetails = lineItemDetails.footerLabels.map(parseFooterData);
            
            returnGroup.lineItemDetails = { footerLabels: parsedLineItemDetails, headerLabels: lineItemDetails.headerLabels, lineButtons: lineItemDetails.lineButtons };
        }

        returnGroup.lineItems = parsedLineItems;
    }
    
    if (fields) {
        const parsedFields = fields.map(parseFieldData);

        returnGroup.fields = parsedFields;
    }

    if (entities) {
        const parsedEntities = entities.map((entity) => parseEntityField(entity));

        returnGroup.entities = parsedEntities;
    }
    
    return returnGroup;
};

const parseEntityField = (entity) => {
    if (!entity) {
        return {};
    }

    const { sections, ...entityProps } = entity;
    const filteredEntityProps = _.omit(entityProps, omittedEntityKeys);

    const returnEntity = { ...filteredEntityProps};

    if (sections) {
        const parsedSections = sections.map(parseSectionData);
        returnEntity.sections = parsedSections;
    }

    return returnEntity;
};

const parseFormData = (formCards) => {
    if (!formCards) {
        return [];
    }

    const parsedFormCard = formCards.map(parseSectionData);
    
    return parsedFormCard;
};

const parseSectionData = (formCard) => {
    const { groups, ...cardProps } = formCard;

    const filteredCardProps = _.omit(cardProps, omittedCardKeys);

    const parsedGroups = groups && groups.map(parseGroupData);

    return {
        ...filteredCardProps,
        ...(isNotNil(parsedGroups) && { groups: parsedGroups }),
    };
};

const parseEntityFormData = (entityForms) => {
    if (!entityForms || !Array.isArray(entityForms)) {
        return entityForms;
    }
    const parsedEntityForms = entityForms.map((entityForm) => {
        const parsedEntityForm = {
            ...entityForm,
        };

        if (entityForm?.section) {
            parsedEntityForm.section = entityForm?.section?.map((entitySection) => {
                return _.omit(entitySection, omittedEntityKeys);
            });
        }

        return parsedEntityForm;
    });

    return parsedEntityForms;
};

export {
    parseEntityField,
    parseSectionData,
    parseFieldData,
    parseGroupData,
    parseFormData,
    parseEntityFormData,
};