// common modules
import {
  Button,
  TextIconSpacing,
  Divider,
  DropdownMenu,
  MenuItem
} from 'react-md';
import { ExpandMoreSVGIcon, ExpandLessSVGIcon, ChevronLeftSVGIcon, ChevronRightSVGIcon } from '@react-md/material-icons';
import { useState, useEffect } from 'react';
import moment from 'moment';

// custom modules
import KeyStatisticTile from '@components/KeyStatisticTile.js';
import api from '@utilities/claApi';
import usePageFramework, { currentYear } from '@utilities/hooks/usePageFramework';
import './MyDashboards.css';
import { STATISTICS_TILES, ASSIGNED_TO_ME, SHOW_ALL } from "@utilities/constants/dashboardStatistic";
import { YYYY_MM_DD } from '@utilities/constants/dateFormats';
import CustomOverlaySpinner from './customOverlaySpinner';

function MyDashboards(props) {
  const { handleViewChange, refreshTrigger, handleTileActive, onOrganizersLoad, refreshStatistics } = props;

  const { authUserEmail } = usePageFramework();
  const itemsPerPage = 6;
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [tileData, setTileData] = useState({});
  const [dashboardMenuItems, setDashboardMenuItems] = useState(ASSIGNED_TO_ME);
  const keyStatMenuItems = 'Year to Date';
  const [activeTile, setActiveTile] = useState(null);
  const [isKeyStatsLoading, setIsKeyStatsLoading] = useState(true);

  const itemsDashboards = [
    <MenuItem key="dash-item-default" data-testid="dashMenuShowMe" className="dashboardSubMenuDropdownItem" onClick={() => { setDashboardMenuItems(ASSIGNED_TO_ME); }}>Assigned to Me</MenuItem>,
    <MenuItem key="dash-item-all" data-testid="dashMenuShowAll" className="dashboardSubMenuDropdownItem" onClick={() => { setDashboardMenuItems(SHOW_ALL); }}>All</MenuItem>
  ];

  // GROWTH: use getCurrentUser and remove authUserEmail once api is synced with users from Azure
  // authUserEmail is retreived via decoding the token on app startup
  // const authUserEmail = '';
  const buildStatisticsQuery = (year, assignmentKey, dateRangeKey) => {
    const yearQuery = `year=${year}`;
    const show = assignmentKey === ASSIGNED_TO_ME ? 'me' : assignmentKey;
    const showQuery = `&show=${show}`;

    let rangeQuery = '';
    switch (dateRangeKey) {
      case 'Last Rolling 7 Days':
        const startDay = moment().subtract(7, 'days').format(YYYY_MM_DD);
        const endDay = moment().add(1, 'days').startOf('day').format(YYYY_MM_DD);
        rangeQuery = `&periodStart=${startDay}&periodEnd=${endDay}`;
        break;
      case 'Month to Date':
        const startMonth = moment().startOf('month').format(YYYY_MM_DD);
        const endMonth = moment().add(1, 'days').startOf('day').format(YYYY_MM_DD);
        rangeQuery = `&periodStart=${startMonth}&periodEnd=${endMonth}`;
        break;
      default:
        break;
    }

    return `${yearQuery}${showQuery}${rangeQuery}`;
  };

  useEffect(() => {
    setIsKeyStatsLoading(refreshStatistics);
  }, [refreshStatistics])

  useEffect(() => {
    const fetchData = async () => {
      let tileDataStatus = {};
      const promises = STATISTICS_TILES.map(async (tile) => {
        tileDataStatus[tile.key] = { isLoading: true, total: 0 };
        let query = tile?.query?.length ? `&filter=${tile.query.join('&filter=')}` : '';

        let assignmentQuery = '';
        if (dashboardMenuItems === ASSIGNED_TO_ME) {
          assignmentQuery = `&filter3=inCharge.email co ${authUserEmail}&filter3=signer.email co ${authUserEmail}&filterMethod3=or`;
        }

        try {
          const response = await api.head(`/organizers?&filterStaticYear=${currentYear}${query}${assignmentQuery}`);
          tileDataStatus[tile.key].total = response.headers.get('X-Total-Count');
        } catch (e) {
          console.log('error', e.message);
        } finally {
          tileDataStatus[tile.key].isLoading = false;
        }
      });
      await Promise.all(promises);
      setTileData({ ...tileDataStatus });
      setIsKeyStatsLoading(false);
      onOrganizersLoad(false);
    };

    fetchData();
  }, [currentYear, dashboardMenuItems, keyStatMenuItems, refreshTrigger]);

  // UseEffect to handle query changes dependent on active tile and assignment
  useEffect(() => {
    if (activeTile) {
      const activeKeyStatisticsQuery = STATISTICS_TILES.find(x => x.key === activeTile)?.query;
      const assignmentQuery = dashboardMenuItems === ASSIGNED_TO_ME ? [`inCharge.email co ${authUserEmail}`, `signer.email co ${authUserEmail}`] : null;
      handleViewChange(activeKeyStatisticsQuery, assignmentQuery);
    } else {
      handleViewChange(null, null);
    }
    onOrganizersLoad(true);
    setIsKeyStatsLoading(true);
  }, [authUserEmail, activeTile, dashboardMenuItems]);

  const handleCardClick = (key) => (event) => {
    event.preventDefault();

    // Only one card can be active at a time
    // An event on an active card will disable the active card
    if (activeTile === key) {
      setActiveTile(null);
      handleTileActive(false)
    } else {
      setActiveTile(key);
      handleTileActive(true)
    }

  };

  const handleChevronLeftClick = () => {
    setCurrentStartIndex(prevIndex => Math.max(prevIndex - itemsPerPage, 0));
  };

  const handleChevronRightClick = () => {
    setCurrentStartIndex(prevIndex => Math.min(prevIndex + itemsPerPage, STATISTICS_TILES.length - itemsPerPage));
  };

  return (
    <div className="my-dashboards">
      <div className="my-dashboards-header">
        <span className="my-dashboards-header-title">My Dashboards</span>
        <DropdownMenu
          id="dashMenu"
          data-testid="dashMenuId"
          icon={<ExpandMoreSVGIcon className="dashboardSubMenuDropdownIcon" />}
          buttonChildren={<TextIconSpacing
            className="dasitemsSorthboardSubMenuDropdownLabel"
          >
            <span className='dashboardSubMenuDropdownSortTextLabel'>Show: </span>
            <span className="dashboardSubMenuDropdownSortTextValue" data-testid="selectedMenu">{dashboardMenuItems}</span>
          </TextIconSpacing>}>
          {itemsDashboards}
        </DropdownMenu>
        <div className="my-dashboards-header-expand">
          <Button
            id="my-dashboards-expand-btn"
            className="my-dashboards-expand-btn"
            onClick={() => setIsCollapsed(!isCollapsed)}>
            <TextIconSpacing
              icon={
                isCollapsed ?
                  <ExpandMoreSVGIcon className="my-dashboards-expand-icon" /> :
                  <ExpandLessSVGIcon className="my-dashboards-expand-icon" />}
              iconAfter
            >
              {isCollapsed ? 'View All' : 'View Less'}
            </TextIconSpacing>
          </Button>
        </div>
      </div>
      <Divider />
      {
        !isCollapsed && tileData &&
        <div className="my-dashboards-content">
          <div className="dashboard-section-header">
            <h1>Key Statistics</h1>
            <DropdownMenu
              id="dashMenu"
              data-testid="periodMenu"
              disableDropdownIcon={true}
              buttonChildren={<TextIconSpacing
                className="dasitemsSorthboardSubMenuDropdownLabel"
              >
                <span className='dashboardSubMenuDropdownSortTextLabel'>Period: </span>
                <span className="dashboardSubMenuDropdownSortTextValue" data-testid="selectedPeriod">{keyStatMenuItems}</span>
              </TextIconSpacing>}>
            </DropdownMenu>
            <div style={{ marginLeft: 'auto', marginTop: '8px' }}>
              <Button
                onClick={handleChevronLeftClick}
                disabled={currentStartIndex === 0}
                className="chevron-button">
                <ChevronLeftSVGIcon className='chevron-icon' />
              </Button>
              <Button
                onClick={handleChevronRightClick}
                disabled={currentStartIndex + itemsPerPage >= STATISTICS_TILES.length}
                className="chevron-button">
                <ChevronRightSVGIcon className='chevron-icon' />
              </Button>
            </div>
          </div>
          <div className="dashboard-section-content">
            <CustomOverlaySpinner isLoading={isKeyStatsLoading} />
            {
              STATISTICS_TILES.slice(currentStartIndex, currentStartIndex + itemsPerPage).map((tile) => {
                const { label, key, query } = tile;
                const isToggled = activeTile === key;
                const value = tileData?.[key]?.total || 0;
                const total = tileData?.total?.total || 0;
                let percent = 0;
                if (total > 0) {
                  percent = Math.round((value / total) * 100);
                }
                return (
                  <KeyStatisticTile
                    key={`statistics-tile-${key}`}
                    label={label}
                    total={value}
                    completed={key !== 'total' ? percent : null}
                    isToggled={isToggled}
                    handleTileClick={handleCardClick(key)}
                  />
                );
              })
            }
          </div>
        </div>
      }
    </div >
  );
}

export default MyDashboards;